.App {
  text-align: left;
  display: flex;
  flex: 1;
  height: auto;
  min-height: 100vh; 
  overflow: hidden;
}

.sidebar {
  background-color: #28314d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 25px;
}

.sidebar h3 a {
  display: flex;
  align-items: center;
}

.sidebar h3 a svg{
    margin: 0 5px;
}

.content {
  flex-grow: 1;
  background-color: whitesmoke;
  color: hsl(0, 0%, 50%);
}

.byte {
  color: whitesmoke;
}

.cycle {
  color: aqua;
}

a:link,
a:visited,
a:active {
  color: hsl(0, 0%, 50%);
  text-decoration: none;
}

a:hover {
  color: aqua;
  text-decoration: none;
}
