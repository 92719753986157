.clientes {
    padding: 30px 45px;
    display: flex;
    justify-content: space-between;
  }

.container > div  {
    margin: 35px;
    height: 20vh;
    width: 30vh;
    background-color: rgb(189, 189, 189);
    color: black;
    padding: 30px 10px;  
  }
