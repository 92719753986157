.containerPedido {
    width: 300px;
    height: 500px;
    margin: 0 auto;
}

.containerPedido > h3 {
    margin: 10px;
    padding: 15px;
}
