.graficos {
  padding: 30px 45px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.imagemGrafico{
  display: flex;
  padding: 5px 45px;
  width: 60%;
}
