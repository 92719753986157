.estoque {
  padding: 30px 45px;
  display: flex;
  justify-content: space-between;
}

.formularioEstoque{

  padding: 0 30px;
  line-height: 5.5;
}



